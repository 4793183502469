<template>
  <div>
    <v-row
      align="end"
      align-content="end"
      justify="center"
      no-gutters
      class="mx-5 mt-8 mb-3 cards"
    >
      <v-col class="d-flex align-center">
        <img :src="menu.title_icon_url" style="width:30px;height:30px" class="mr-1" />
        <span class="itemCaption">{{menu.title}}</span>
      </v-col>
      <v-col class="text-right" v-if="dataExists">
        <v-btn
          rounded
          elevation="0"
          small
          class="itemButton maincolor"
          @click.stop="$emit('set', menu.button_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)"
        >すべて表示</v-btn>
      </v-col>
    </v-row>

    <!-- content body -->
    <div v-if="!dataExists">
      <v-card width="336px" height="307px" class="mx-5 elevation-6">
        <div class="mt-2" style="width: 336px; height: 307px; overflow: hidden;">
          <v-row
            align="center"
            justify="center"
            no-gutters
            style="background-color: #FFFFFF;"
            class="px-2 pt-3 pb-3"
          >
            <v-col cols="4">
              <v-btn
                rounded
                outlined
                disabled
                small
                style="font: bold 9px/12px 'YuGothic';"
                width="83px"
              >
                現在トーク中の
                <br />店舗
              </v-btn>
            </v-col>
            <v-col cols="7">
              <p class="mb-0" style="font: bold 11px/22px 'YuGothic';">お店を選択する</p>
            </v-col>
            <v-col cols="1">
              <!-- NEW_DEV-1508 cyber start -->
              <a @click.stop="$emit('set', menu.button_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)">
                <v-icon>keyboard_arrow_right</v-icon>
              </a>
              <!-- NEW_DEV-1508 cyber end -->
            </v-col>
          </v-row>

          <div
            style="background-color: #F9F9F9; height: 411px; overflow-y: scroll; position: relative; z-index: 1;"
          >
            <v-row align="center" justify="center" no-gutters style="height: 200px;">
              <p style="font: normal 12px/16px 'YuGothic';" class="mb-0">まだトークのやりとりはありません。</p>
            </v-row>
          </div>
          <div
            class="text-center"
            style="position: absolute; bottom: 0; left:0; z-index: 2; border-top:1px solid rgba(200,199,204,0.5); width:100%; padding: 15px 0;"
          >
            <v-btn
              rounded
              class="maincolor-bg default_button"
              @click.stop="$emit('set', menu.button_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)"
            >コメントを投稿する</v-btn>
          </div>
        </div>
      </v-card>
    </div>

    <div v-if="dataExists">
      <v-card width="336px" height="307px" class="mx-5 elevation-6">
        <div class="mt-2" style="width: 336px; height: 307px; overflow: hidden;">
          <v-row
            align="center"
            justify="center"
            no-gutters
            style="background-color: #FFFFFF;"
            class="px-2 pt-3 pb-3"
          >
            <v-col cols="4">
              <v-btn
                rounded
                outlined
                disabled
                small
                style="font: bold 9px/12px 'YuGothic';"
                width="83px"
                @click.stop="NextRouting(menu.button_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)"
              >
                現在トーク中の
                <br />店舗
              </v-btn>
            </v-col>
            <v-col cols="7">
              <p class="mb-0" style="font: bold 11px/22px 'YuGothic';">{{talk.shop_name}}</p>
            </v-col>
            <v-col cols="1">
              <!-- NEW_DEV-1508 cyber start -->
              <a @click="toTalkDetail()">
                <v-icon>keyboard_arrow_right</v-icon>
              </a>
              <!-- NEW_DEV-1508 cyber end -->
            </v-col>
          </v-row>

          <div
            style="background-color: #F9F9F9; height: 258px; overflow-y: scroll; position: relative; z-index: 1;"
            class="pb-12"
          >
            <div v-for="(item, i) in talk.data" :key="i" style="padding-top: 8px;">
              <v-row class="ml-2 mb-3 mt-0" style="left:20px">
                <p
                  class="mb-0 mr-2"
                  style="font: bold 11px/11px 'Roboto'; color: #C4C4C4;"
                >{{formatDate(new Date(item.create_date.replace(/-/g, '/')), 'YYYY年MM月DD日 hh:mm')}}</p>
              </v-row>
              <v-row
                align="end"
                justify="start"
                no-gutters
                class="ml-3 mb-5"
                v-if="item.admin_member_flg == 1"
              >
                <p style="font: normal 12px/16px 'YuGothic';" class="mb-0" v-html="item.content"></p>
              </v-row>
              <v-row align="end" justify="end" no-gutters class="mr-3 mb-5" v-else>
                <v-col cols="8">
                  <div
                    class="elevation-2"
                    style="width: 188px; background-color: #FF00B110; border-radius: 16px 16px 0px 16px;"
                    v-if="item.content_type == 1 || item.content_type == 3"
                  >
                    <p style="font: normal 12px/16px 'YuGothic';" class="pa-4 mb-0" v-html="item.content"></p>
                  </div>
                  <div
                    class="elevation-2"
                    style="width: 188px; background-color: #FF00B110; border-radius: 16px 16px 0px 16px;"
                    v-else
                  >
                    <v-img :src="item.comment_image_url"></v-img>
                  </div>
                </v-col>
              </v-row>

              <!-- 日付ボーダー -->
              <!-- <div
                class="text-center my-5 mx-4"
                style="height: 20px;"
                v-if="item.border_flg == 1"
              >
                <v-btn
                  rounded
                  outlined
                  disabled
                  x-small
                  class="elevation-1"
                  style="font: normal 11px/11px '游ゴシック体'; position: relative; z-index: 2; background-color: #FFFFFF; border: 0;"
                  width="110px"
                >{{formatDate(new Date(item.create_date), 'YYYY年MM月DD日')}}</v-btn>
                <hr
                  color="#DEDEE0"
                  size="1px"
                  style="position: relative; top: -13px; z-index: 1;"
                />
              </div> -->
            </div>
          </div>

          <div style="position: relative; top: -64px; z-index: 2;">
            <v-row align="end" justify="center" no-gutters class="px-2">
            <v-btn
              rounded
              class="maincolor-bg default_button "
              @click="toTalkDetail()"
            >コメントを投稿する</v-btn>
            </v-row>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
export default {
  props: ['menu', 'talk'],
  watch: {
    talk: {
      handler: function (v) {
        if (v && v.data.length != 0) {
          this.dataExists = true;
        }
      },
      deep: true
    }
  },
  data () {
    return {
      dataExists: false
    };
  },
  created() {
  },
  methods: {
    displayTime(datatime) {
      var date = new Date(datatime);
      var diff = new Date().getTime() - date.getTime();
      var d = new Date(diff);

      if (d.getUTCFullYear() - 1970) {
        return d.getUTCFullYear() - 1970 + "年前";
      } else if (d.getUTCMonth()) {
        return d.getUTCMonth() + "ヶ月前";
      } else if (d.getUTCDate() - 1) {
        return d.getUTCDate() - 1 + "日前";
      } else if (d.getUTCHours()) {
        return d.getUTCHours() + "時間前";
      } else if (d.getUTCMinutes()) {
        return d.getUTCMinutes() + "分前";
      } else {
        return d.getUTCSeconds() + "秒前";
      }
    },
    formatDate(date, format) {
      if (!format) format = "YYYY-MM-DD hh:mm:ss.SSS";
      format = format.replace(/YYYY/g, date.getFullYear());
      format = format.replace(/MM/g, ("0" + (date.getMonth() + 1)).slice(-2));
      format = format.replace(/DD/g, ("0" + date.getDate()).slice(-2));
      format = format.replace(/hh/g, ("0" + date.getHours()).slice(-2));
      format = format.replace(/mm/g, ("0" + date.getMinutes()).slice(-2));
      format = format.replace(/ss/g, ("0" + date.getSeconds()).slice(-2));
      if (format.match(/S/g)) {
        var milliSeconds = ("00" + date.getMilliseconds()).slice(-3);
        var length = format.match(/S/g).length;
        for (var i = 0; i < length; i++)
          format = format.replace(/S/, milliSeconds.substring(i, i + 1));
      }
      return format;
    },
    toTalkDetail(){
      var destination = encodeURI('https://' + process.env.VUE_APP_HOST_NAME + "/sp/talk/talk?shop_id=" + this.talk.data[0].shop_id + "&selected_shop=" + this.talk.shop_name)
      var feature_id = 1
      var screen_id = "talk"
      var transition_type = 2
      var top_type = 1
      var cassette_id = 1
      var transition_info = "talk"
      var page_title = this.menu.title

      this.NextRouting(
        destination,
        feature_id,
        screen_id,
        transition_type,
        top_type,
        cassette_id,
        transition_info,
        page_title,
      )
    },
    async NextRouting(
      destination,
      feature_id,
      screen_id,
      transition_type,
      top_type,
      cassette_id,
      transition_info,
      page_title,
    ) {
      var tonative_req = {
        destination: destination,
        feature_id: feature_id,
        screen_id: screen_id,
        transition_type: transition_type,
        page_title: page_title,
      };

      if (!this.screenTransition(tonative_req)) {
        this.loading = false;
        // 遷移失敗・遷移情報が変な時もエラー表示があった方が良い？
        this.callDialog(
          "遷移エラー",
          "遷移情報が不正です。再度アクセスして下さい。",
          2
        );
      }

    }
  },
}
</script>

<style scoped>
.buttonA {
  width: 143px;
  height: 32px;
  color: #ffffff;
  background-color: var(--main_color) !important;
  border-radius: 20px;
  font: bold 11px "Roboto"; /* 本来14pxだが、それに合わせると見た目がズレるので微修正した */
}

/* 基本カードレイアウト */
.cards {
  position: relative;
  z-index: 15;
}
.itemCaption {
  line-height: 1;
}
.itemButton {
  width: 120px;
  height: 30px !important;
  padding: 0 !important;
  background-color: #ffffff !important;
  filter: drop-shadow(0 0 10px #00000033);
}
.itemNon {
  /* データが無い場合 */
  width: 335px;
  height: 180px;
  border-radius: 16px 16px 16px 16px !important;
  text-align: center;
  line-height: 180px;
  color: #9d9d9d;
  font-size: 14px;
}
.itemOne {
  /* データが1件だけの場合 */
  width: 335px;
  height: 314px;
  border-radius: 16px 16px 16px 16px !important;
}
.itemList {
  /* データが2件以上の場合 */
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.itemList::-webkit-scrollbar {
  display: none;
}
.itemList li {
  display: inline-block;
}
.itemCard {
  width: 305px;
  height: 314px;
  border-radius: 16px 16px 16px 16px !important;
  overflow: auto;
  /* filter: drop-shadow(0px 5px 15px #00000066); */
}

/* 基本カード内のレイアウト */
.itemOne .v-image,
.itemList .v-image {
  border-radius: 16px 16px 0px 0px !important;
}
.itemFirstTime {
  margin: 0 0 0 16px;
  width: 78px;
  height: 14px;
  font-size: 9px;
  color: #ffffff;
  background-color: #eb762f !important;
}
.itemTitle {
  margin: 22px 0 0 0;
  font-size: 14px;
  line-height: 20px;
}
.itemSubTitle {
  margin: 0 0 0 16px;
  font-size: 9px;
  line-height: 13px;
}
.itemUnread {
  margin: 0 16px 2px 0;
  display: inline-flex;
  vertical-align: bottom;
  height: 14px;
  font-size: 9px;
  color: #ffffff;
  background-color: #9d9d9d !important;
}
</style>
