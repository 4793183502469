<template>
  <!-- 共通部分、app.vueにv-appとv-containerなどが既にある -->
  <v-content class="top-a" :style="(init.preview == 1) ? 'padding-bottom:70px;' : ''">
    <component :is="'style'" type="text/css" v-if="init.top_background_image">
      .wrap::before {
        background-image: url({{init.top_background_image}}) !important;
        background-size: cover !important;
        content: "";
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100vh;
      }
    </component>
    <div class="wrap" style="min-height:100vh;">
    <!-- logo -->
    <v-row align-content="center" justify="center" no-gutters class="logo" :style="{
        backgroundImage: 'url(' + init.top_background_image + ') !important',
        backgroundSize: 'cover !important'
    }">
      <img v-if="init.top_logo_image" :src="init.top_logo_image" style="width:auto; max-width:100%;" />
      <span v-else style="font-weight: bold; font-size: 22px;">{{init.shop_name}}</span>
    </v-row>

    <!-- background & scroll -->
    <div class="body pb-10">
      <!-- content -->
      <div v-for="(menu, i) in menu_list" :key="i">
        <!-- coupon -->
        <div v-if="menu.feature_id == 'coupon'">
          <TopBCoupon :menu="menu" :coupon_list="coupon_list" @set="NextRouting" />
        </div>

        <!-- stamp card -->
        <div v-else-if="menu.feature_id == 'stamp'">
          <TopBStamp :menu="menu" :stamp_list="stamp_list" @set="NextRouting" />
        </div>
        <!-- reserve -->
        <div v-else-if="menu.feature_id == 'booking'">
          <TopBBooking :menu="menu" :booking="booking" @set="NextRouting" />
        </div>
        <!-- degital tickets -->
        <div v-else-if="menu.feature_id == 'digital_ticket'">
          <TopBTicket :menu="menu" :digital_list="digital_list" @set="NextRouting" />
        </div>
        <!-- news -->
        <div v-else-if="menu.feature_id == 'notification'">
          <TopBNotification :menu="menu" :notification_list="notification_list" @set="NextRouting" />
        </div>
        <!-- TALK -->
        <div v-if="menu.feature_id == 'talk'">
          <TopBTalk :menu="menu" :talk="talk" @set="NextRouting" />
        </div>
        <!-- POINT-A コンプリート式 -->
        <div v-else-if="menu.content_id == 'point_a' && point_ab">
          <TopBPoint :menu="menu" :type="'a'" :point_ab="point_ab" @set="NextRouting" />
        </div>
        <!-- POINT-B 加算減算式 -->
        <div v-else-if="menu.content_id == 'point_b' && point_ab">
          <TopBPoint :menu="menu" :type="'b'" :point_ab="point_ab" @set="NextRouting" />
        </div>
        <!-- 外部リンク -->
        <div v-if="menu.feature_id == 'external_links' + menu.shop_menu_id">
          <topBExternalLink
            :menu="menu"
            @set="NextRouting"
          ></topBExternalLink>
        </div>

        <!-- original pay -->
        <!--
        <div v-else-if="menu.content_id == 'original_pay' && booking">
          <v-row align="end" align-content="end" justify="center" no-gutters class="mx-5 mt-11 mb-8">
            <v-col class="d-flex align-center">
              <img :src="menu.title_icon_url" class="mr-1" /><span class="itemCaption">{{menu.title}}</span>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-card class="itemCard original mx-auto">
              <v-row no-gutters><v-card class="payCard mx-10 elevation-10" :img="pay"></v-card></v-row>
              <v-row no-gutters class="mx-auto" style="width: 290px; height: 120px;">
                <v-row no-gutters align="end" justify="end" style="width: 290px;">
                  <span class="mr-2" style="font: bold 14px;">残高</span>
                  <span style="font: bold 30px 'Oswald';">{{originalPayAmount}}</span>
                  <span class="ml-1" style="font: bold 16px; filter: drop-shadow(0 3px 6px #00000029);">円</span>
                </v-row>
                <v-row no-gutters justify="end" style="width: 290px; font-size: 9px; color: #B4B4B4;">{{originalPayDate}}</v-row>
                <v-row no-gutters justify="space-between" style="width: 290px;">
                  <v-col style="display: contents;">
                    <v-btn class="buttonA" depressed style="width: 140px; height: 32px; background-color: #FFFFFF !important; border: 1px solid #FF00B1; color: #FF00B1;">履歴を見る</v-btn>
                  </v-col>
                  <v-col style="display: contents;">
                    <v-btn class="buttonA" depressed style="width: 140px; height: 32px;">チャージ</v-btn>
                  </v-col>
                </v-row>
              </v-row>
            </v-card>
          </v-row>
        </div>
        -->

        <!-- mobile order&pay -->
        <!--
        <v-row align="end" align-content="end" justify="center" no-gutters class="mx-5 mt-11 mb-3">
          <v-col cols="8" class="d-flex align-center">
            <img :src="menu.title_icon_url" class="mr-1" /><span class="itemCaption">MOBILE ORDER &amp;PAY</span>
          </v-col>
          <v-col cols="4" class="text-right"><v-btn rounded elevation="0" small class="itemButton">すべて表示</v-btn></v-col>
        </v-row>
        <v-row no-gutters>
          <v-card class="itemCard original mx-auto">
          </v-card>
        </v-row>
        -->
      </div>
      <!-- /v-for /content -->
    </div>
    <!-- /background & scroll -->
    </div>

  <GlobalFooter v-if="init.preview == 1" :menus="init.menu_data" />
  </v-content>
</template>

<script>
import TopBCoupon from "@/views/top/topBCoupon.vue";
import TopBPoint from "@/views/top/topBPoint.vue";
import TopBTalk from "@/views/top/topBTalk.vue";
import TopBStamp from "@/views/top/topBStamp.vue";
import TopBBooking from "@/views/top/topBBooking.vue";
import TopBTicket from "@/views/top/topBTicket.vue";
import TopBNotification from "@/views/top/topBNotification.vue";
import topBExternalLink from "@/views/top/topBExternalLink.vue";
import GlobalFooter from "@/components/global_footer.vue"
export default {
  components: {
    TopBPoint,
    TopBTalk,
    TopBStamp,
    TopBCoupon,
    TopBBooking,
    TopBTicket,
    TopBNotification,
    topBExternalLink,
    GlobalFooter,
  },
  data() {
    return {
      logo: this.storageGet("init_top_log_image"), //top-aの「NATIVE GET」テストボタンクリック後の動作確認済
      back: require("@/assets/top-b-back1.png"),
      menu_list: null, //今まで[]だったが、APIにて、データが取得できなかった際、nullの方がタグ側のif文が組みやすい。
      coupon_list: null,
      stamp_list: null,
      digital_list: null,
      digital_purchased_list: null,
      notification_list: null,
      talk: null,
      point_ab: null,
      booking: null,
      init: [],
      getParams: [],
      badgeParams: {},
    };
  },
  async created() {
    this.init = this.storageGet("*");

    /* axiosで画面表示時、トップメニューリスト情報取得API実行 */
    let default_params = {
      sid: this.init.sid,
      device_uid: this.init.device_uid,
      key: this.init.key,
      device_id: this.init.device_id,
      os: this.init.os,
      login_id: this.init.login_id,
      preview: this.$route.query.preview, //isPreview Getパラメータがある場合は、APIにisPreviewを渡す
      // 20201105 外部リンク追加対応 cyber 董 start
      createFlag: this.$route.query.createFlag, //未保存が新規プレビューデータ表示・非表示フラグ
      // 20201105 外部リンク追加対応 cyber 董 end
    };
    let menu_res = await this.apiCall("user/topmenu/2", { params: default_params });
    if (!menu_res) return false; // resが返らない時のエラーは共通エラー処理でやるので、処理中断だけで良い。
    this.$set(this, "menu_list", menu_res.menus);

    let api_req = default_params;
    let api_info = {
      coupon: { url: "user/coupons", save_name: "coupon_list" },
      stamp: { url: "stamp/top-list", save_name: "stamp_list" },
      digital_ticket: {
        url: "digitalticket/list",
        save_name: "digital_list"
      },
      notification: {
        url: "user/notifications",
        save_name: "notification_list"
      },
      talk: { url: "talk/timeline", save_name: "talk" },
      point: { url: "point", save_name: "point_ab" },
      booking: { url: "user/booking", save_name: "booking" }
    };
    // 各メニューデータ取得
    let api_res = null;
    for (var i = 0; i < this.menu_list.length; i++) {
      switch (this.menu_list[i].feature_id) {
        case "coupon":
        case "notification":
          Object.assign(api_req, { shop_id: this.init.sid, page: 1 });
          break;
        case "stamp":
          Object.assign(api_req, { shop_id: this.init.sid, page: 1, limit: 3 });
          break;
        case "digital_ticket":
          Object.assign(api_req, {
            shop_id: this.init.sid,
            is_bought: 0,
            page: 1
          });
          break;
        case "talk":
          var talk_sid = this.$route.query.shop_id ? this.$route.query.shop_id : this.init.sid;
          //20210122 NEW_DEV-743 cyber start
          Object.assign(api_req, { shop_id: talk_sid, page: 1, device_uid: this.init.device_uid, isTop:1 });
          //202101222 NEW_DEV-743 cyber end
          break;
        case "booking":
          Object.assign(api_req, { page: 1, limit: 1 });
          break;
      }

      if (api_info[this.menu_list[i].feature_id]) {
        api_res = await this.apiCall(
          api_info[this.menu_list[i].feature_id]["url"],
          { params: api_req }
        );

        if (!api_res) continue; // resが返らない時のエラーは共通エラー処理でやるので、処理中断だけで良い。
        this.$set(
          this,
          api_info[this.menu_list[i].feature_id]["save_name"],
          api_res
        );
      }
    }
  },
  watch: { //init apiが読み込まれたか監視
    '$root.init_flg': 'fetchData',
  },
  methods: {
    fetchData(){ //init apiが読み込まれた場合initに値を入れる
        this.init = this.storageGet('*')

        //バッジ数をネイティブに送る
        this.sendBadgeConditionToNative();
    },
    async NextRouting(
      destination,
      feature_id,
      screen_id,
      transition_type,
      top_type,
      cassette_id,
      transition_info,
      page_title,
    ) {
      try {
        // プレビューの場合は通常遷移させる
        if (this.$route.query.preview) {
          return window.location.href = destination + '?sid=' + this.init.sid + '&preview=1';
        }
        //タップAPI
        var tap_req = {
          sid: this.init.sid,
          service_cd: this.init.service_cd,
          device_uid: this.init.device_uid,
          device_id: this.init.device_id,
          os: this.init.os,
          key: this.init.key,
          top_type: top_type,
          cassette_id: cassette_id,
          transition_type: transition_type,
          transition_info: transition_info
        };

        var tap_res = await this.apiCall("/user/tap", tap_req, "post");
        if (!tap_res) return false;

        //ルーティングAPI
        var tonative_req = {
          destination: destination,
          feature_id: feature_id,
          screen_id: screen_id,
          transition_type: transition_type,
          page_title: page_title,
        };

        if (!this.screenTransition(tonative_req)) {
          this.loading = false;
          // 遷移失敗・遷移情報が変な時もエラー表示があった方が良い？
          this.callDialog(
            "遷移エラー",
            "遷移情報が不正です。再度アクセスして下さい。",
            2
          );
        }
      } catch (e) {
        this.callDialog(
          "通信エラー",
          "ネットワークの状態が不安定です。再度お試しください",
          3
        );
      }
    }
  }
};
</script>

<style scoped>
/* 基本的にAdobe XD側はptだが、コーディングした際にpt→pxの方が表示乱れが起きない為、変換して対応する */
/* ページレイアウト */
.top-b {
  width: 375px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
  font-family: "游ゴシック体"; /* ページ内のデフォルトのフォント設定 */
}

.body {
  overflow-y: auto;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.body::-webkit-scrollbar {
  /* Chrome, Safari 対応 */
  display: none;
}

.buttonA {
  width: 143px;
  height: 32px;
  color: #ffffff;
  background-color: var(--main_color) !important;
  border-radius: 20px;
  font: bold 11px "Roboto"; /* 本来14pxだが、それに合わせると見た目がズレるので微修正した */
}

/* 基本カードレイアウト */
.cards {
  position: relative;
  z-index: 10;
}
.itemCaption {
  line-height: 1;
}
.itemButton {
  width: 120px;
  height: 30px !important;
  padding: 0 !important;
  background-color: #ffffff !important;
  filter: drop-shadow(0 0 10px #00000033);
}
.itemNon {
  /* データが無い場合 */
  width: 335px;
  height: 180px;
  border-radius: 16px 16px 16px 16px !important;
  text-align: center;
  line-height: 180px;
  color: #9d9d9d;
  font-size: 14px;
}
.itemOne {
  /* データが1件だけの場合 */
  width: 335px;
  height: 314px;
  border-radius: 16px 16px 16px 16px !important;
}
.itemList {
  /* データが2件以上の場合 */
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.itemList::-webkit-scrollbar {
  display: none;
}
.itemList li {
  display: inline-block;
}
.itemCard {
  width: 305px;
  height: 314px;
  border-radius: 16px 16px 16px 16px !important;
  overflow: auto;
  /* filter: drop-shadow(0px 5px 15px #00000066); */
}

/* 基本カード内のレイアウト */
.itemOne .v-image,
.itemList .v-image {
  border-radius: 16px 16px 0px 0px !important;
}
.itemFirstTime {
  margin: 0 0 0 16px;
  width: 78px;
  height: 14px;
  font-size: 9px;
  color: #ffffff;
  background-color: #eb762f !important;
}
.itemTitle {
  margin: 22px 0 0 0;
  font-size: 14px;
  line-height: 20px;
}
.itemSubTitle {
  margin: 0 0 0 16px;
  font-size: 9px;
  line-height: 13px;
}
.itemUnread {
  margin: 0 16px 2px 0;
  display: inline-flex;
  vertical-align: bottom;
  height: 14px;
  font-size: 9px;
  color: #ffffff;
  background-color: #9d9d9d !important;
}

/* カードの種類(基本との差分のみ記述) */
.reserve {
  height: 215px;
}
.original {
  width: 335px;
  height: 285px;
  overflow: visible;
}
.payCard {
  width: 260px;
  height: 164px;
  top: -20px;
  border-radius: 8px 8px 8px 8px !important;
}
.tickets {
  height: 168px;
}
.news {
  width: 193px;
  height: 270px;
}
</style>
