<template>
  <div>
    <v-row
      align="end"
      align-content="end"
      justify="center"
      no-gutters
      class="mx-5 mt-8 mb-3 cards"
    >
      <v-col class="d-flex align-center">
        <img :src="menu.title_icon_url" style="width:30px;height:30px" class="mr-1" />
        <span class="itemCaption">{{menu.title}}</span>
      </v-col>
      <v-col class="text-right" v-if="dataExists">
        <v-btn
          v-if="notification_list.data.length == 1"
          rounded
          elevation="0"
          small
          class="itemButton"
          @click="$router.push({ name: 'notification_detail', params: { id: notification_list.data[0].id }})"
        >詳細を見る</v-btn>
        <v-btn
          v-if="notification_list.data.length != 0"
          rounded
          elevation="0"
          small
          class="itemButton maincolor"
          @click.stop="$emit('set', menu.button_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)"
        >すべて表示</v-btn>
      </v-col>
    </v-row>

    <!-- content body -->
    <v-row no-gutters v-if="!dataExists">
      <v-card
        class="itemNon mx-5 elevation-3"
        :img="noNotificationBack"
      >現在配信されているお知らせはありません</v-card>
    </v-row>
    <v-row no-gutters v-if="dataExists">
      <ul class="itemList pr-5">
          <li v-for="(notification, i) in notification_list.data" :key="i">
          <v-card width="193px" class="ml-5 elevation-3" style="padding: 0 0 5px 0;">
            <v-img
              :src="notification.image_url"
              height="160px"
              style="border-radius: 16px 16px 0 0;"
            ></v-img>
            <div class="newribbon" v-if="notification .new_flg == 1">
              <span>NEW</span>
            </div>
            <v-btn
              rounded
              outlined
              disabled
              x-small
              class="mx-2 mt-2"
              style="font: bold 12px/20px '游ゴシック体';"
              width="177px"
              @click="$router.push({ name: 'notification_detail', params: { id: notification.id }})"
            >{{notification.type_name}}</v-btn>
            <p
              class="mb-0 mt-2 mx-2"
              style="font: normal 10px/20px 'YuGothic'; width: 177px; height: 32px;"
            >{{notification.title}}</p>
            <v-row align="end" justify="center" no-gutters class="mx-2 mt-2">
              <v-col cols="9">
                <p
                  class="mb-0"
                  style="font: normal 9px/13px 'YuGothic';"
                >配信日：{{notification.start_date}}～{{notification.end_date}}</p>
                <p
                  class="mb-0"
                  style="font: normal 9px/13px 'YuGothic';"
                >対象店舗：{{notification.shop_name}}</p>
              </v-col>
              <v-col cols="3" class="text-right">
                <v-chip
                  v-if="notification.read_flg == 0"
                  x-small
                  color="#9D9D9D"
                  style="color: #FFFFFF;"
                >未読</v-chip>
              </v-col>
            </v-row>
          </v-card>
        </li>
      </ul>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['menu', 'notification_list'],
  watch: {
    notification_list: {
      handler: function (v) {
        if (v && v.data && v.data.length > 0) {
          this.dataExists = true;
        }
      },
      deep: true
    }
  },
  data () {
    return {
      dataExists: false,
      noNotificationBack: require("@/assets/top-b-back6.png"), // TODO 画像なし
    };
  },
  created() {
  },
  methods: {}
}
</script>
<style scoped>
.buttonA {
  width: 143px;
  height: 32px;
  color: #ffffff;
  background-color: var(--main_color) !important;
  border-radius: 20px;
  font: bold 11px "Roboto"; /* 本来14pxだが、それに合わせると見た目がズレるので微修正した */
}
/* 基本カードレイアウト */
.cards {
  position: relative;
  z-index: 15;
}
.itemCaption {
  line-height: 1;
}
.itemButton {
  width: 120px;
  height: 30px !important;
  padding: 0 !important;
  background-color: #ffffff !important;
  filter: drop-shadow(0 0 10px #00000033);
}
.itemNon {
  /* データが無い場合 */
  width: 335px;
  height: 180px;
  border-radius: 16px 16px 16px 16px !important;
  text-align: center;
  line-height: 180px;
  color: #9d9d9d;
  font-size: 14px;
}
.itemOne {
  /* データが1件だけの場合 */
  width: 335px;
  height: 314px;
  border-radius: 16px 16px 16px 16px !important;
}
.itemList {
  /* データが2件以上の場合 */
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.itemList::-webkit-scrollbar {
  display: none;
}
.itemList li {
  display: inline-block;
}
.itemCard {
  width: 305px;
  height: 314px;
  border-radius: 16px 16px 16px 16px !important;
  overflow: auto;
  /* filter: drop-shadow(0px 5px 15px #00000066); */
}

/* 基本カード内のレイアウト */
.itemOne .v-image,
.itemList .v-image {
  border-radius: 16px 16px 0px 0px !important;
}
.itemFirstTime {
  margin: 0 0 0 16px;
  width: 78px;
  height: 14px;
  font-size: 9px;
  color: #ffffff;
  background-color: #eb762f !important;
}
.itemTitle {
  margin: 22px 0 0 0;
  font-size: 14px;
  line-height: 20px;
}
.itemSubTitle {
  margin: 0 0 0 16px;
  font-size: 9px;
  line-height: 13px;
}
.itemUnread {
  margin: 0 16px 2px 0;
  display: inline-flex;
  vertical-align: bottom;
  height: 14px;
  font-size: 9px;
  color: #ffffff;
  background-color: #9d9d9d !important;
}
.news {
  width: 193px;
  height: 270px;
}
</style>
