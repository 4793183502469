<template>
  <div v-if="type == 'a'">
    <!-- content header ループで一括処理しないのは、必ずしも全てのAPI結果がcontent_id.dataのような形で来るとは限らない為 -->
    <v-row
      align="end"
      align-content="end"
      justify="center"
      no-gutters
      class="mx-5 mt-8 mb-3 cards"
    >
      <v-col class="d-flex align-center">
        <img :src="menu.title_icon_url" style="width:30px;height: 30px" class="mr-1" />
        <span class="itemCaption">{{menu.title}}</span>
      </v-col>
      <v-col class="text-right">
        <v-btn
          rounded
          elevation="0"
          small
          class="itemButton"
          @click.stop="$emit('set', menu.button_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)"
        >すべて表示</v-btn>
      </v-col>
    </v-row>

    <!-- content body -->
    <v-card width="336px" height="300px" class="mx-5 elevation-6">
      <div style="height: 0px; position: relative; top: -4px; z-index: 2;">
        <v-img class="ml-2" :src="point_ab.rank_img_path" width="50px" height="50px"></v-img>
      </div>
      <v-row
        align="center"
        justify="center"
        no-gutters
        style="width: 336px; height: 24px; border-radius: 8px 8px 0px 0px; position: relative; z-index: 1;"
        class="maincolor-bg"
      >
        <p
          class="mb-0 text-center"
          style="color: #FFFFFF; font: bold 12px/20px 'YuGothic';"
        >現在までの取得ポイント</p>
      </v-row>
      <div style="width: 336px; height: 19px;">
        <p
          class="mt-2 mb-0 text-center maincolor"
          style="font: bold 12px/19px 'YuGothic';"
        >初回ダウンロード特典</p>
      </div>

      <div class="mt-2 text-center">
        <div class="text-center" style="color: #000000; font: bold 20px/24px 'Oswald';">
          <span style="font: bold 54px/54px 'Oswald';">{{pointNum}}</span>
          <span
            style="font: normal 30px/40px '游ゴシック体'; position: relative; top: -4px;" class="maincolor"
          >/</span>
          <span>{{point_ab.grant_point}}</span>
          <span style="font: bold 12px/16px 'Oswald';">point</span>
          <p class="mb-0 maincolor" style="font: bold 9px/13px 'YuGothic';">
            <span class="mr-4">コンプリートまであと</span>
            <span
              style="font: bold 20px/24px 'Oswald';"
            >{{point_ab.grant_point - point_ab.point}}</span>
            <span style="font: bold 12px/24px 'Oswald';">point</span>
          </p>
        </div>
      </div>

      <div class="mx-3 mt-2 text-center" style="height: 22px;">
        <hr color="#C8C7CC" size="1px" />
        <p
          class="mb-0 text-center"
          style="color: #B4B4B4; font: bold 11px/16px 'YuGothic';"
        >ポイント有効期限：{{point_ab.expiraton_date}}</p>
        <hr color="#C8C7CC" size="1px" />
      </div>

      <v-row align="center" justify="center" no-gutters class="mt-2">
        <span style="color: #B4B4B4; font: bold 9px/16px 'YuGothic';" class="mr-3">現在のランク</span>
        <span style="color: #333333; font: bold 16px/24px 'YuGothic';">{{point_ab.rank}}</span>
      </v-row>

      <v-row
        align="start"
        justify="center"
        no-gutters
        class="mt-2"
        style="min-height: 95px; background-color: #F9F9F9;"
      >
        <v-col cols="9" style="color: #9D9D9D; font: bold 8px/10px 'Roboto';" class="pl-5">
          <p class="mb-0 mt-3">ユーザー情報</p>
          <v-row class="mt-2" align="start" justify="center" no-gutters>
            <v-col cols="3">ID</v-col>
            <v-col
              cols="9"
              style="color: #333333; font: bold 10px/10px 'Roboto';"
            >{{point_ab.user_id}}</v-col>
          </v-row>
          <v-row class="mt-2" align="start" justify="center" no-gutters>
            <v-col cols="3">NAME</v-col>
            <v-col
              cols="9"
              style="color: #333333; font: bold 10px/10px 'Roboto';"
            >{{point_ab.user_name}} さん</v-col>
          </v-row>
          <p
            class="mb-0 mt-1"
            style="font: normal 8px/11px 'YuGothic';"
          >ポイント取得の際は、QRコードをスタッフへご提示ください</p>
        </v-col>
        <v-col cols="3" style="margin:auto;">
          <qrcode :value="qrcodeurl" :options="{ width: 70, margin: 1 }"></qrcode>
        </v-col>
      </v-row>
    </v-card>
  </div>
  <div v-else>
    <v-row
      align="end"
      align-content="end"
      justify="center"
      no-gutters
      class="mx-5 mt-8 mb-3 cards"
    >
      <v-col class="d-flex align-center">
        <img :src="menu.title_icon_url" class="mr-1" />
        <span class="itemCaption">{{menu.title}}</span>
      </v-col>
      <v-col class="text-right">
        <v-btn
          rounded
          elevation="0"
          small
          class="itemButton"
          @click.stop="$emit('set', menu.button_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)"
        >すべて表示</v-btn>
      </v-col>
    </v-row>

    <!-- content body -->
    <v-card width="336px" height="300px" class="mx-5 elevation-6">
      <div style="height: 0px; position: relative; top: -4px; z-index: 2;">
        <v-img class="ml-2" :src="point_ab.rank_img_path" width="50px" height="50px"></v-img>
      </div>
      <v-row
        align="center"
        justify="center"
        no-gutters
        style="width: 336px; height: 24px; border-radius: 8px 8px 0px 0px; position: relative; z-index: 1;"
        class="maincolor-bg"
      >
        <p
          class="mb-0 text-center"
          style="color: #FFFFFF; font: bold 12px/20px 'YuGothic';"
        >ご利用可能ポイント</p>
      </v-row>
      <div style="width: 336px; height: 19px;">
        <p
          class="mt-2 mb-0 text-center maincolor"
          style="font: bold 12px/19px 'YuGothic';"
        >初回ダウンロード特典</p>
      </div>

      <div class="mt-4 text-center">
        <div class="text-center" style="color: #000000; font: bold 20px/24px 'Oswald';">
          <span style="font: bold 54px/54px 'Oswald';">{{pointNum}}</span>
          <span style="font: bold 12px/16px 'Oswald';">point</span>
        </div>
      </div>

      <div class="mx-3 mt-8 text-center" style="height: 22px;">
        <hr color="#C8C7CC" size="1px" />
        <p
          class="mb-0 text-center"
          style="color: #B4B4B4; font: bold 11px/16px 'YuGothic';"
        >ポイント有効期限：{{point_ab.expiraton_date}}</p>
        <hr color="#C8C7CC" size="1px" />
      </div>

      <v-row align="center" justify="center" no-gutters class="mt-2">
        <span style="color: #B4B4B4; font: bold 9px/16px 'YuGothic';" class="mr-3">現在のランク</span>
        <span style="color: #333333; font: bold 16px/24px 'YuGothic';">{{point_ab.rank}}</span>
      </v-row>

      <v-row
        align="start"
        justify="center"
        no-gutters
        class="mt-2"
        style="min-height: 95px; background-color: #F9F9F9;"
      >
        <v-col cols="9" style="color: #9D9D9D; font: bold 8px/10px 'Roboto';" class="pl-5">
          <p class="mb-0 mt-3">ユーザー情報</p>
          <v-row class="mt-2" align="start" justify="center" no-gutters>
            <v-col cols="3">ID</v-col>
            <v-col
              cols="9"
              style="color: #333333; font: bold 10px/10px 'Roboto';"
            >{{point_ab.user_id}}</v-col>
          </v-row>
          <v-row class="mt-2" align="start" justify="center" no-gutters>
            <v-col cols="3">NAME</v-col>
            <v-col
              cols="9"
              style="color: #333333; font: bold 10px/10px 'Roboto';"
            >
              <span v-if="point_ab.user_name">{{point_ab.user_name}}</span><span v-else>ゲスト</span> さん
            </v-col>
          </v-row>
          <p
            class="mb-0 mt-1"
            style="font: normal 8px/11px 'YuGothic';"
          >ポイント取得の際は、QRコードをスタッフへご提示ください</p>
        </v-col>
        <v-col cols="3" style="margin:auto;">
          <qrcode :value="qrcodeurl" :options="{ width: 70, margin: 1 }"></qrcode>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ['menu', 'point_ab', 'type'],
  watch: {
    point_ab: function () {
      let count = setInterval(function () {
        if (this.pointNum == this.point_ab.point) {
          clearInterval(count);
        }  else {
          this.pointNum++;
        }
      }.bind(this), 10);
    }
  },
  data () {
    return {
      init: [],
      pointNum: 0,
      qrcodeurl: null,
      noPointBack: require("@/assets/top-b-back6.png"), // TODO 画像なし
    };
  },
  created() {
    this.init = this.storageGet("*");

    // ポイントQRコード
    this.qrcodeurl = "https://" + location.host + "/points/" + this.init.user_id + "/edit?shopId=" + this.init.sid + "&shopName=" + this.init.shop_name;
  },
  methods: {}
}
</script>

<style scoped>
  .itemButton {
    width: 120px;
    height: 30px !important;
    padding: 0 !important;
    background-color: #ffffff !important;
    -webkit-filter: drop-shadow(0 0 10px #00000033);
    filter: drop-shadow(0 0 10px #00000033);
  }
</style>
