<template>
  <div>
    <v-row align="end" justify="center" no-gutters class="mx-5 mt-8 mb-3">
      <v-col class="d-flex align-center">
        <img :src="menu.title_icon_url" style="width:30px;height:30px" class="mr-1" />
        <span class="itemCaption">{{menu.title}}</span>
      </v-col>
      <div v-if="degitalTicketsPurchased && purchasedDataExists">
        <v-col class="text-right">
          <v-btn
            v-if="digital_purchased_list.data.length == 1"
            rounded
            elevation="0"
            small
            class="itemButton maincolor"
          >詳細を表示</v-btn>
          <v-btn
            v-if="digital_purchased_list.data.length > 1"
            rounded
            elevation="0"
            small
            class="itemButton maincolor"
            @click.stop="$emit('set', menu.button_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)"
          >すべて表示</v-btn>
        </v-col>
      </div>
      <div v-else-if="!degitalTicketsPurchased && dataExists">
        <v-col class="text-right">
          <v-btn
            v-if="digital_list.data.length == 1"
            rounded
            elevation="0"
            small
            class="itemButton maincolor"
          >詳細を表示</v-btn>
          <v-btn
            v-if="digital_list.data.length > 1"
            rounded
            elevation="0"
            small
            class="itemButton maincolor"
            @click.stop="$emit('set', menu.button_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)"
          >すべて表示</v-btn>
        </v-col>
      </div>
    </v-row>

    <v-row
      no-gutters
      class="mx-5 mb-4"
      style="width: 336px; height: 30px; background-color: #FFFFFF; border-radius: 16px;"
    >
      <v-col>
        <v-btn
          v-if="degitalTicketsPurchased"
          depressed
          style="width: 168px; height: 30px; background-color: #FFFFFF; border-radius: 20px; color: #FF00B1; font: bold 11px 'Roboto';"
          @click="degitalTicketsChange()"
        >販売中チケット</v-btn>
        <v-btn
          v-if="!degitalTicketsPurchased"
          class="buttonA elevation-3"
          depressed
          style="width: 168px; height: 30px;"
        >販売中チケット</v-btn>
      </v-col>
      <v-col>
        <v-btn
          v-if="degitalTicketsPurchased"
          class="buttonA elevation-3"
          depressed
          style="width: 168px; height: 30px;"
        >購入済みチケット</v-btn>
        <v-btn
          v-if="!degitalTicketsPurchased"
          depressed
          style="width: 168px; height: 30px; background-color: #FFFFFF; border-radius: 20px; color: #FF00B1; font: bold 11px 'Roboto';"
          @click="degitalTicketsChange()"
        >購入済みチケット</v-btn>
      </v-col>
    </v-row>

    <!-- content body -->
    <v-row no-gutters
      v-if="(degitalTicketsPurchased && !purchasedDataExists) || (!degitalTicketsPurchased && !dataExists)"
    >
      <v-card
        class="itemNon stamp mx-5 elevation-3"
        :img="noTicketBack"
      >現在<span v-if="degitalTicketsPurchased">までに購入された</span><span v-else>販売中の</span>チケットはありません</v-card>
    </v-row>

    <v-row no-gutters style="overflow: auto;" v-else>
      <ul v-if="!degitalTicketsPurchased" class="itemList pr-5">
        <!-- 販売中チケット -->
        <li v-for="(digital, i) in digital_list.data" :key="i">
          <v-card class="itemCard tickets ml-5 elevation-3" style="overflow: hidden;">
            <v-row
              no-gutters
              align="center"
              justify="start"
              style="width: 305px; height: 168px; background-color: #FFFFFF;"
            >
              <v-col>
                <v-row
                  v-if="digital.limit_type"
                  align="center"
                  justify="center"
                  no-gutters
                  style="width: 70px; height: 54px; background-color: #35D4CA; color: #FFFFFF;"
                >
                  <span style="font-size: 11px; line-height: 8px; height: 24px;">
                    - {{digital.limit_type_text}} -
                    <br />
                    <span
                      style="font: bold 20px 'Roboto'; margin: 0 2px 0 0;"
                    >{{digital.limit_number}}</span>名
                  </span>
                </v-row>
                <v-row
                  align="end"
                  justify="center"
                  no-gutters
                  style="width: 70px; height: 48px;"
                >
                  <img :src="degitalTicketsIcon" style="width: 41px; height: 26px;" />
                </v-row>
                <v-row
                  align="start"
                  justify="center"
                  no-gutters
                  style="width: 70px; height: 66px;"
                >
                  <v-chip
                    outlined
                    text-color="#9D9D9D"
                    x-small
                    style="font-size: 9px; padding: 0 8px; margin: 17px 0 0 0;"
                  >{{digital.ticket_type_text}}</v-chip>
                </v-row>
              </v-col>
              <v-col
                v-bind:style="{ width: '235px', 'background-image': 'url('+digital.image_path+')'}"
              >
                <v-row
                  no-gutters
                  align="start"
                  justify="start"
                  style="width: 235px; height: 168px;"
                >
                  <v-col class="font-weight-bold" cols="11">
                    <p
                      style="margin: 16px 0 0 16px; width: 189px; height: 34px; font: bold 14px/18px '游ゴシック体';"
                    >{{digital.title}}</p>
                    <div style="margin: 6px 0 0 16px; width: 189px; height: 23px;">
                      <span style="font: bold 14px/24px '游ゴシック体';">300</span>
                      <span style="font-size: 9px;">円</span>
                      <v-chip
                        outlined
                        text-color="#333333"
                        x-small
                        style="margin: 0 0 0 6px; font-size: 8px; padding: 0 8px; border: solid 1px; height: 14px;"
                      >税込</v-chip>
                    </div>
                    <p
                      style="margin: 13px 0 0 16px; width: 189px; height: 38px; font: bold 8px/14px '—';"
                    >{{digital.terms_of_use_text}}</p>
                    <v-btn
                      v-if="digital.sales_promotion_text"
                      rounded
                      depressed
                      color="#E55497"
                      dark
                      style="margin: 10px 0 0 16px; font: normal 9px/14px '游ゴシック体'; width: 189px; height: 18px;"
                    >{{digital.sales_promotion_text}}</v-btn>
                  </v-col>
                  <v-col cols="1" align-self="center" style="position: relative; left: -8px;">
                    <a>
                      <v-icon color="#9D9D9D">keyboard_arrow_right</v-icon>
                    </a>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </li>
      </ul>
      <ul v-else-if="digital_purchased_list" class="itemList pr-5">
        <!-- 購入済みチケット -->
        <li v-for="(digital, i) in digital_purchased_list.data" :key="i">
          <v-card class="itemCard tickets ml-5 elevation-3" style="overflow: hidden;">
            <v-row
              no-gutters
              align="center"
              justify="start"
              style="width: 305px; height: 168px; background-color: #FFFFFF;"
            >
              <v-col>
                <v-row
                  v-if="digital.limit_type"
                  align="center"
                  justify="center"
                  no-gutters
                  style="width: 70px; height: 54px; background-color: #35D4CA; color: #FFFFFF;"
                >
                  <span style="font-size: 11px; line-height: 8px; height: 24px;">
                    - {{digital.limit_type_text}} -
                    <br />
                    <span
                      style="font: bold 20px 'Roboto'; margin: 0 2px 0 0;"
                    >{{digital.limit_number}}</span>名
                  </span>
                </v-row>
                <v-row
                  align="end"
                  justify="center"
                  no-gutters
                  style="width: 70px; height: 48px;"
                >
                  <img :src="degitalTicketsIcon" style="width: 41px; height: 26px;" />
                </v-row>
                <v-row
                  align="start"
                  justify="center"
                  no-gutters
                  style="width: 70px; height: 66px;"
                >
                  <v-chip
                    outlined
                    text-color="#9D9D9D"
                    x-small
                    style="font-size: 9px; padding: 0 8px; margin: 17px 0 0 0;"
                  >{{digital.ticket_type_text}}</v-chip>
                </v-row>
              </v-col>
              <v-col
                v-bind:style="{ width: '235px', 'background-image': 'url('+digital.image_path+')'}"
              >
                <v-row
                  no-gutters
                  align="start"
                  justify="start"
                  style="width: 235px; height: 168px;"
                >
                  <v-col class="font-weight-bold" cols="11">
                    <p
                      style="margin: 16px 0 0 16px; width: 189px; height: 34px; font: bold 14px/18px '游ゴシック体';"
                    >{{digital.title}}</p>
                    <div style="margin: 6px 0 0 16px; width: 189px; height: 23px;">
                      <span style="font: bold 14px/24px '游ゴシック体';">300</span>
                      <span style="font-size: 9px;">円</span>
                      <v-chip
                        outlined
                        text-color="#333333"
                        x-small
                        style="margin: 0 0 0 6px; font-size: 8px; padding: 0 8px; border: solid 1px; height: 14px;"
                      >税込</v-chip>
                    </div>
                    <p
                      style="margin: 13px 0 0 16px; width: 189px; height: 38px; font: bold 8px/14px '—';"
                    >{{digital.terms_of_use_text}}</p>
                    <v-btn
                      rounded
                      depressed
                      color="#E55497"
                      dark
                      style="margin: 10px 0 0 16px; font: normal 9px/14px '游ゴシック体'; width: 189px; height: 18px;"
                    >チケットを使用する</v-btn>
                  </v-col>
                  <v-col cols="1" align-self="center" style="position: relative; left: -8px;">
                    <a>
                      <v-icon color="#9D9D9D">keyboard_arrow_right</v-icon>
                    </a>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </li>
      </ul>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['menu', 'digital_list'],
  watch: {
    digital_list: {
      handler: function (v) {
        if (v && v.data && v.data.length > 0) {
          this.dataExists = true;
        }
      },
      deep: true
    },
    digital_purchased_list: {
      handler: function (v) {
        if (v && v.data) {
          this.purchasedDataExists = true;
        }
      },
      deep: true
    },
  },
  data () {
    return {
      dataExists: false,
      purchasedDataExists: false,
      digital_purchased_list: null,
      noTicketBack: require("@/assets/top-b-back6.png"), // TODO 画像なし
      noNotificationBack: require("@/assets/top-b-back6.png"), // TODO 画像なし
      degitalTicketsBack: require("@/assets/top-b-back7.png"),
      degitalTicketsPurchased: false,
      degitalTicketsIcon: require("@/assets/top-b-icon2.png"),
    };
  },
  created() {
    this.init = this.storageGet("*");
  },
  methods: {
    async degitalTicketsChange() {
      if (!this.degitalTicketsPurchased) {
        this.degitalTicketsPurchased = true;
        // 購入済みデータがなければ、デジタルチケットリスト情報取得API実行
        if (!this.digital_purchased_list) {
          let req = {
            sid: this.init.sid,
            device_uid: this.init.device_uid,
            key: this.init.key,
            device_id: this.init.device_id,
            os: this.init.os,
            shop_id: this.init.sid,
            is_bought: 1,
            page: 1
          };
          let res = await this.apiCall('/digitalticket/list', {params:req});
          if (!res) return false; // resが返らない時のエラーは共通エラー処理でやるので、処理中断だけで良い。
          this.$set(this, "digital_purchased_list", res);
        }
      } else {
        this.degitalTicketsPurchased = false;
      }
    }
  }
}
</script>
<style scoped>
.buttonA {
  width: 143px;
  height: 32px;
  color: #ffffff;
  background-color: var(--main_color) !important;
  border-radius: 20px;
  font: bold 11px "Roboto"; /* 本来14pxだが、それに合わせると見た目がズレるので微修正した */
}
/* 基本カードレイアウト */
.cards {
  position: relative;
  z-index: 15;
}
.itemCaption {
  line-height: 1;
}
.itemButton {
  width: 120px;
  height: 30px !important;
  padding: 0 !important;
  background-color: #ffffff !important;
  filter: drop-shadow(0 0 10px #00000033);
}
.itemNon {
  /* データが無い場合 */
  width: 335px;
  height: 180px;
  border-radius: 16px 16px 16px 16px !important;
  text-align: center;
  line-height: 180px;
  color: #9d9d9d;
  font-size: 14px;
}
.itemOne {
  /* データが1件だけの場合 */
  width: 335px;
  height: 314px;
  border-radius: 16px 16px 16px 16px !important;
}
.itemList {
  /* データが2件以上の場合 */
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.itemList::-webkit-scrollbar {
  display: none;
}
.itemList li {
  display: inline-block;
}
.itemCard {
  width: 305px;
  height: 314px;
  border-radius: 16px 16px 16px 16px !important;
  overflow: auto;
  /* filter: drop-shadow(0px 5px 15px #00000066); */
}

/* 基本カード内のレイアウト */
.itemOne .v-image,
.itemList .v-image {
  border-radius: 16px 16px 0px 0px !important;
}
.itemFirstTime {
  margin: 0 0 0 16px;
  width: 78px;
  height: 14px;
  font-size: 9px;
  color: #ffffff;
  background-color: #eb762f !important;
}
.itemTitle {
  margin: 22px 0 0 0;
  font-size: 14px;
  line-height: 20px;
}
.itemSubTitle {
  margin: 0 0 0 16px;
  font-size: 9px;
  line-height: 13px;
}
.itemUnread {
  margin: 0 16px 2px 0;
  display: inline-flex;
  vertical-align: bottom;
  height: 14px;
  font-size: 9px;
  color: #ffffff;
  background-color: #9d9d9d !important;
}

.tickets {
  height: 168px;
}
</style>
