<template>
  <div>
    <v-row
      align="end"
      align-content="end"
      justify="center"
      no-gutters
      class="mx-5 mt-8 mb-3 cards"
    >
      <v-col class="d-flex align-center">
        <img v-if="menu.title_icon_url" :src="menu.title_icon_url" style="width:30px;height:30px" class="mr-1" />
        <span class="itemCaption">{{menu.title}}</span>
      </v-col>
      <v-col class="text-right" v-if="dataExists">
        <v-btn
          v-if="coupon_list.total_coupon_count == 1"
          rounded
          elevation="0"
          small
          class="itemButton"
          @click="$router.push({ name: 'coupon_detail', params: { id: coupon_list.data[0].id }})"
        >詳細を見る</v-btn>
        <v-btn
          v-else-if="coupon_list.total_coupon_count > 1"
          rounded
          elevation="0"
          small
          class="itemButton maincolor"
          @click.stop="$emit('set', menu.button_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)"
        >すべて表示</v-btn>
      </v-col>
    </v-row>
    <!-- content body -->
    <v-row no-gutters v-if="!dataExists">
      <v-card
        class="itemNon mx-5 elevation-3"
        :img="noCouponBack"
      >現在利用できるクーポンはありません</v-card>
    </v-row>

    <v-row no-gutters v-if="dataExists">
      <v-card
        v-if="coupon_list.total_coupon_count == 1"
        class="itemOne mx-5 elevation-3"
      >
        <v-row align-content="start" justify="center" no-gutters>
          <v-img :src="coupon_list.data[0].image_url" height="204px"></v-img>
        </v-row>
        <v-row
          class="align-center"
          justify="start"
          no-gutters
          v-if="coupon_list.data[0].distribution_type"
          style="height: 0;"
        >
          <v-chip class="itemFirstTime px-4">{{coupon_list.data[0].distribution_type}}</v-chip>
        </v-row>
        <v-row align-content="center" justify="center" no-gutters>
          <v-card-title
            class="itemTitle font-weight-bold mx-4 pa-0"
          >{{coupon_list.data[0].title}}</v-card-title>
        </v-row>
        <v-row align="end" justify="center" no-gutters class="mt-3">
          <v-col cols="8">
            <p class="itemSubTitle font-weight-medium">{{coupon_list.data[0].shop_names}}</p>
            <p
              class="itemSubTitle font-weight-medium"
            >{{coupon_list.data[0].disp_date}}</p>
          </v-col>
          <v-col col="4" class="text-right">
            <v-chip v-if="coupon_list.data[0].is_read == 0" class="itemUnread">未読</v-chip>
          </v-col>
        </v-row>
      </v-card>
      <ul class="itemList pr-5" v-else>
        <li v-for="(coupon, i) in coupon_list.data" :key="i">
          <v-card class="itemCard ml-5 elevation-3">
            <v-row align-content="start" justify="center" no-gutters>
              <v-img :src="coupon.image_url" height="204px"></v-img>
            </v-row>
            <v-row
              class="align-center"
              justify="start"
              no-gutters
              v-if="coupon.distribution_type"
              style="height: 0;"
            >
              <v-chip class="itemFirstTime px-4">初回DL特典</v-chip>
            </v-row>
            <v-row align-content="center" justify="center" no-gutters>
              <v-card-title class="itemTitle font-weight-bold mx-4 pa-0">{{coupon.title}}</v-card-title>
            </v-row>
            <v-row align="end" justify="center" no-gutters class="mt-3">
              <v-col cols="8">
                <p class="itemSubTitle font-weight-medium">{{coupon.shop_names}}</p>
                <p
                  class="itemSubTitle font-weight-medium"
                >{{coupon.disp_date}}</p>
              </v-col>
              <v-col col="4" class="text-right">
                <v-chip v-if="coupon.is_read" class="itemUnread">未読</v-chip>
              </v-col>
            </v-row>
          </v-card>
        </li>
      </ul>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['menu', 'coupon_list'],
  watch: {
    coupon_list: {
      handler: function (v) {
        if (v && v.total_coupon_count > 0) {
          this.dataExists = true;
        }
      },
      deep: true
    }
  },
  data () {
    return {
      dataExists: false,
      noCouponBack: require("@/assets/top-b-back2.png"),
    };
  },
  created() {
  },
  methods: {}
}
</script>
<style scoped>
.buttonA {
  width: 143px;
  height: 32px;
  color: #ffffff;
  background-color: var(--main_color) !important;
  border-radius: 20px;
  font: bold 11px "Roboto"; /* 本来14pxだが、それに合わせると見た目がズレるので微修正した */
}
/* 基本カードレイアウト */
.cards {
  position: relative;
  z-index: 15;
}
.itemCaption {
  line-height: 1;
}
.itemButton {
  width: 120px;
  height: 30px !important;
  padding: 0 !important;
  background-color: #ffffff !important;
  filter: drop-shadow(0 0 10px #00000033);
}
.itemNon {
  /* データが無い場合 */
  width: 335px;
  height: 180px;
  border-radius: 16px 16px 16px 16px !important;
  text-align: center;
  line-height: 180px;
  color: #9d9d9d;
  font-size: 14px;
}
.itemOne {
  /* データが1件だけの場合 */
  width: 335px;
  height: 314px;
  border-radius: 16px 16px 16px 16px !important;
}
.itemList {
  /* データが2件以上の場合 */
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.itemList::-webkit-scrollbar {
  display: none;
}
.itemList li {
  display: inline-block;
  vertical-align: top;
}
.itemCard {
  width: 305px;
  height: 314px;
  border-radius: 16px 16px 16px 16px !important;
  overflow: auto;
  /* filter: drop-shadow(0px 5px 15px #00000066); */
}

/* 基本カード内のレイアウト */
.itemOne .v-image,
.itemList .v-image {
  border-radius: 16px 16px 0px 0px !important;
}
.itemFirstTime {
  margin: 0 0 0 16px;
  min-width: 78px;
  height: 14px;
  font-size: 9px;
  color: #ffffff !important;
  background-color: #eb762f !important;
}
.itemTitle {
  margin: 22px 0 0 0;
  font-size: 14px;
  line-height: 20px;
}
.itemSubTitle {
  margin: 0 0 0 16px;
  font-size: 9px;
  line-height: 13px;
}
.itemUnread {
  margin: 0 16px 2px 0;
  display: inline-flex;
  vertical-align: bottom;
  height: 14px;
  font-size: 9px;
  color: #ffffff !important;
  background-color: #9d9d9d !important;
}
.align-center {
  -webkit-align-content: center !important;
  -ms-align-content: center !important;
  align-content: center !important;
}
</style>
