<template>
  <div>
    <v-row
      align="end"
      align-content="end"
      justify="center"
      no-gutters
      class="mx-5 mt-8 mb-3 cards"
    >
      <v-col class="d-flex align-center">
        <img :src="menu.title_icon_url" style="width:30px;height:30px" class="mr-1" />
        <span class="itemCaption">{{menu.title.substring(0,13)}}</span>
      </v-col>
      <v-col class="text-right">
        <v-btn
          rounded
          elevation="0"
          small
          class="itemButton maincolor"
          @click.stop="$emit('set', menu.link_external_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)"
        >詳細を見る</v-btn>
      </v-col>
    </v-row>
    <!-- content body -->
    <v-row no-gutters>
      <v-card
        class="itemOne mx-5 elevation-3"
        style="width: 335px; height: 407px; border-radius: 16px;"
      >
        <div width="335px" height="204px">
          <v-img :src="menu.link_image_path" @click.stop="$emit('set', menu.link_external_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)"
          width="335px" height="204px"></v-img>
        </div>
        <v-row
          align="center"
          justify="center"
          no-gutters
          class="pd-0"
          style="width: 335px; height: 143px;"
        >
          <p
            class="mx-11 mb-0 "
            style="width: 305px; min-height: 34px; overflow: hidden; font: bold 14px '游ゴシック体'; text-align: center; margin-top: 30px; white-space: pre-wrap;"
            :style="{color: menu.link_headline_color}"
          >{{menu.link_headline | headLineClip}}</p>
          <p v-if="menu.link_title_view_flg == 1"
            class="mb-0"
            style="width: 335px; height: 22px; font: bold 24px '游ゴシック体'; text-align: center; margin-top: 21px;" 
            :style="{color: menu.link_title_color}"
          >{{menu.link_title.substring(0,10)}}</p>
          <p v-if="menu.link_title_view_flg != 1"
            class="mb-0"
            style="width: 335px; height: 22px; font: bold 24px '游ゴシック体'; text-align: center; margin-top: 21px;" 
          >&nbsp;</p>
          <v-row align="start" justify="center" no-gutters style="margin-top: 34px; margin-bottom: 30px;">
            <v-btn 
            rounded
            class="maincolor-bg" width="200px" style="font: bold 14px/24px '游ゴシック体'; height: 32px;"
            @click.stop="$emit('set', menu.link_external_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)">
              {{menu.link_button_name.substring(0,12)}}
            </v-btn>
          </v-row>
        </v-row>
      </v-card>

    </v-row>
  </div>
</template>

<script>
export default {
  props: ['menu', 'link'],
  data () {
    return {
      noReserveBack: require("@/assets/top-b-back6.png"),
    };
  },
  created() {
  },
  methods: {},
  filters: {
    headLineClip (value) {
      if (value) {
        let tmpValue = '';
        value.split(/\n/g).forEach(function (val, idx, arr) {
          if (val.length > 17) {
            val = val.substring(0, 17) + '\n' + val.substring(17);
          }
          if (idx != arr.length - 1){
            val = val + '\n';
          }
          tmpValue = tmpValue.concat(val.toString());
        });
        if (tmpValue.split(/\n/g).length > 1) {
          value = tmpValue.split(/\n/g)[0] + '\n' + tmpValue.split(/\n/g)[1];
        } else {
          value = tmpValue.split(/\n/g)[0];
        }
      }
      return value;
    }
  }
}
</script>
<style scoped>
p{
  margin-block-start:0;
  margin-block-end:0;
}
.v-border-radius {
  border-radius: 16px 16px 0 0;
}
.buttonA {
  width: 143px;
  height: 32px;
  color: #ffffff;
  background-color: var(--main_color) !important;
  border-radius: 20px;
  font: bold 11px "Roboto"; /* 本来14pxだが、それに合わせると見た目がズレるので微修正した */
}
/* 基本カードレイアウト */
.cards {
  position: relative;
  z-index: 15;
}
.itemCaption {
  line-height: 1;
  font: bold 12px "游ゴシック体";
  white-space: nowrap;
}
.itemButton {
  width: 120px;
  height: 30px !important;
  padding: 0 !important;
  background-color: #ffffff !important;
  filter: drop-shadow(0 0 10px #00000033);
  font: bold 12px "Hiragino Kaku Gothic Pro,W3";
}
.itemNon {
  /* データが無い場合 */
  width: 335px;
  height: 180px;
  border-radius: 16px 16px 16px 16px !important;
  text-align: center;
  line-height: 180px;
  color: #9d9d9d;
  font-size: 14px;
}
.itemOne {
  /* データが1件だけの場合 */
  width: 335px;
  height: 314px;
  border-radius: 16px 16px 16px 16px !important;
}
.itemList {
  /* データが2件以上の場合 */
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.itemList::-webkit-scrollbar {
  display: none;
}
.itemList li {
  display: inline-block;
}
.itemCard {
  width: 305px;
  height: 314px;
  border-radius: 16px 16px 16px 16px !important;
  overflow: auto;
  /* filter: drop-shadow(0px 5px 15px #00000066); */
}

/* 基本カード内のレイアウト */
.itemOne .v-image,
.itemList .v-image {
  border-radius: 16px 16px 0px 0px !important;
}
.itemFirstTime {
  margin: 0 0 0 16px;
  width: 78px;
  height: 14px;
  font-size: 9px;
  color: #ffffff;
  background-color: #eb762f !important;
}
.itemTitle {
  margin: 22px 0 0 0;
  font-size: 14px;
  line-height: 20px;
}
.itemSubTitle {
  margin: 0 0 0 16px;
  font-size: 9px;
  line-height: 13px;
}
.itemUnread {
  margin: 0 16px 2px 0;
  display: inline-flex;
  vertical-align: bottom;
  height: 14px;
  font-size: 9px;
  color: #ffffff;
  background-color: #9d9d9d !important;
}
</style>
