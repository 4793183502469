<template>
  <div>
    <v-row
      align="end"
      align-content="end"
      justify="center"
      no-gutters
      class="mx-5 mt-8 mb-3 cards"
    >
      <v-col class="d-flex align-center">
        <img :src="menu.title_icon_url" style="width:30px;height:30px" class="mr-1" />
        <span class="itemCaption">{{menu.title}}</span>
      </v-col>
      <v-col class="text-right" v-if="dataExists">
        <v-btn
          v-if="booking.data.length == 1"
          rounded
          elevation="0"
          small
          class="itemButton maincolor"
          @click="$router.push({ name: 'booking', params: { id: booking.data[0].id }})"
          @click.stop="$emit('set', detailUrl, menu.feature_id, detailScreenId, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)"
        >詳細を見る</v-btn>
        <v-btn
          v-else-if="booking.data.length > 1"
          rounded
          elevation="0"
          small
          class="itemButton maincolor"
          @click.stop="$emit('set', menu.button_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)"
        >すべて表示</v-btn>
      </v-col>
    </v-row>
    <!-- content body -->
    <v-row no-gutters v-if="!dataExists">
      <v-card
        class="itemNon mx-5 elevation-3"
        style="height: 180px;"
        :img="noReserveBack"
      >現在利用できる予約サービスはありません</v-card>
    </v-row>
    <v-row no-gutters v-if="dataExists">
      <v-card
        v-if="booking.data.length == 1"
        class="itemOne mx-5 elevation-3"
        style="width: 335px; height: 215px; border-radius: 16px; overflow: auto;"
      >
        <v-row
          class="maincolor-bg_opacity"
          align="center"
          justify="center"
          no-gutters
          style="width: 335px; height: 143px;"
        >
          <p
            class="ma-2 mb-0"
            style="width: 305px; overflow: hidden; font: bold 14px; text-align: center;"
          >{{booking.data[0].shop_name}}
          </p>
          <p
            class="mb-0"
            style="width: 335px; overflow: hidden; font: bold 14px; text-align: center;"
          >{{booking.data[0].title}}</p>
          <p
            class="text-justify ma-4 mt-0"
            style="width: 335px; height: 106px; overflow: hidden; font-size: 12px;"
          >{{booking.data[0].description}}</p>
        </v-row>
        <v-row
          align="center"
          justify="center"
          no-gutters
          class="elevation-24"
          style="width: 335px; height: 72px; background-color: #FFFFFF;"
        >
          <v-btn
            class="buttonA"
            depressed
            style="width: 200px; height: 32px;"
            @click.stop="$emit('set', detailUrl, menu.feature_id, detailScreenId, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)"
          >予約詳細を見る</v-btn>
        </v-row>
      </v-card>
      <ul class="itemList pr-5" v-else>
        <li v-for="(booking, i) in booking.data" :key="i">
          <v-card class="itemCard reserve ml-5 elevation-3" style="overflow: auto;">
            <v-row
              class="maincolor-bg_opacity"
              align="center"
              justify="center"
              no-gutters
              style="width: 305px; height: 143px;"
            >
              <p
                class="ma-2 mb-0"
                style="width: 305px; overflow: hidden; font: bold 14px; text-align: center;"
              >{{booking.shop_name}}
              </p>
              <p
                class="mb-0"
                style="width: 305px; overflow: hidden; font: bold 14px; text-align: center;"
              >{{booking.title}}
              </p>
              <p
                class="text-justify ma-4 mt-0"
                style="width: 305px; height: 106px; overflow: hidden; font-size: 12px;"
              >{{booking.description}}</p>
            </v-row>
            <v-row
              align="center"
              justify="center"
              no-gutters
              class="elevation-3"
              style="width: 305px; height: 72px; background-color: #FFFFFF;"
            >
              <v-btn
                class="buttonA"
                depressed
                style="width: 200px; height: 32px;"
                @click="$router.push({ name: 'booking', params: { id: booking.id }})"
                @click.stop="$emit('set', detailUrl, menu.feature_id, detailScreenId, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)"
                >予約詳細を見る</v-btn>
            </v-row>
          </v-card>
        </li>
      </ul>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['menu', 'booking'],
  watch: {
    booking: {
      handler: function (v) {
        if (v && v.data && v.data.length > 0) {
          this.dataExists = true;
          let tmpUrl = this.menu.button_url.replace('shoplist', 'detail');
          this.detailUrl = tmpUrl + '?id=' + this.booking.data[0].id;
        }
      },
      deep: true
    }
  },
  data () {
    return {
      dataExists: false,
      noReserveBack: require("@/assets/top-b-back6.png"),
      detailUrl: null,
      detailScreenId: 'booking'
    };
  },
  created() {},
  methods: {}
}
</script>
<style scoped>
.buttonA {
  width: 143px;
  height: 32px;
  color: #ffffff;
  background-color: var(--main_color) !important;
  border-radius: 20px;
  font: bold 11px "Roboto"; /* 本来14pxだが、それに合わせると見た目がズレるので微修正した */
}
/* 基本カードレイアウト */
.cards {
  position: relative;
  z-index: 15;
}
.itemCaption {
  line-height: 1;
}
.itemButton {
  width: 120px;
  height: 30px !important;
  padding: 0 !important;
  background-color: #ffffff !important;
  filter: drop-shadow(0 0 10px #00000033);
}
.itemNon {
  /* データが無い場合 */
  width: 335px;
  height: 180px;
  border-radius: 16px 16px 16px 16px !important;
  text-align: center;
  line-height: 180px;
  color: #9d9d9d;
  font-size: 14px;
}
.itemOne {
  /* データが1件だけの場合 */
  width: 335px;
  height: 314px;
  border-radius: 16px 16px 16px 16px !important;
}
.itemList {
  /* データが2件以上の場合 */
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.itemList::-webkit-scrollbar {
  display: none;
}
.itemList li {
  display: inline-block;
}
.itemCard {
  width: 305px;
  border-radius: 16px 16px 16px 16px !important;
  overflow: auto;
  /* filter: drop-shadow(0px 5px 15px #00000066); */
}

/* 基本カード内のレイアウト */
.itemOne .v-image,
.itemList .v-image {
  border-radius: 16px 16px 0px 0px !important;
}
.itemFirstTime {
  margin: 0 0 0 16px;
  width: 78px;
  height: 14px;
  font-size: 9px;
  color: #ffffff;
  background-color: #eb762f !important;
}
.itemTitle {
  margin: 22px 0 0 0;
  font-size: 14px;
  line-height: 20px;
}
.itemSubTitle {
  margin: 0 0 0 16px;
  font-size: 9px;
  line-height: 13px;
}
.itemUnread {
  margin: 0 16px 2px 0;
  display: inline-flex;
  vertical-align: bottom;
  height: 14px;
  font-size: 9px;
  color: #ffffff;
  background-color: #9d9d9d !important;
}
</style>
