<template>
  <div>
    <v-row
      align="end"
      align-content="end"
      justify="center"
      no-gutters
      class="mx-5 mt-8 mb-3 cards"
    >
      <v-col class="d-flex align-center">
        <img :src="menu.title_icon_url" style="width:30px;height:30px" class="mr-1" />
        <span class="itemCaption">{{menu.title}}</span>
      </v-col>
      <v-col class="text-right" v-if="dataExists">
        <v-btn
          v-if="stamp_list.data.length == 1"
          rounded
          elevation="0"
          small
          class="itemButton maincolor"
          @click="$router.push({ name: 'stamp_detail', params: { id: stamp_list.data[0].id }})"
        >詳細を見る</v-btn>
        <v-btn
          v-else-if="stamp_list.data.length > 1"
          rounded
          elevation="0"
          small
          class="itemButton maincolor"
          @click.stop="$emit('set', menu.button_url, menu.feature_id, menu.screen_id, menu.transition_type, menu.top_type, menu.cassette_id, menu.transition_info, menu.title)"
        >すべて表示</v-btn>
      </v-col>
    </v-row>
    <!-- content body -->
    <v-row no-gutters v-if="!dataExists">
      <v-card
        class="itemNon stamp mx-5 elevation-3"
        :img="noStampBack"
      >現在利用できるスタンプはありません</v-card>
    </v-row>
    <v-row no-gutters v-if="dataExists">
      <v-card
        v-if="stamp_list.data.length == 1"
        class="itemOne mx-5 elevation-3"
        style="width: 335px; height: 180px; border-radius: 16px;"
      >
        <v-row
          align-content="start"
          justify="center"
          no-gutters
          style="width: 336px; height: 180px;"
        >
          <v-col class="col-auto" style="width: 150px;">
            <v-img :src="stamp_list.data[0].image_path" width="150px" height="180px"></v-img>
          </v-col>
          <v-col class="col-auto" style="width: 165px;">
            <v-row no-gutters style="height: 65px;" class="align-center">
              <p
                class="pt-3 pl-2"
                style="width: 143px; height: 14px; font-size: 14px; line-height: 14px;"
              >{{stamp_list.data[0].title}}</p>
            </v-row>
            <v-row style="min-height:70px" no-gutters>
              <p
                v-if="stamp_list.data[0].expiration_date"
                class="pl-2 mb-0 expiration_date_text"
                style="width: 143px;"
              >有効期限：{{stamp_list.data[0].expiration_date}}<br>
              対象店舗：{{stamp_list.data[0].shop_names}}
              </p>
              <p
                v-else
                class="pl-2 mb-0 expiration_date_text"
                style="width: 143px;"
              >対象店舗：{{stamp_list.data[0].shop_names}}
              </p>
            </v-row>
            <v-row class="align-end" justify="center" no-gutters>
              <v-btn class="buttonA maincolor-bg" depressed @click.stop="toStampAuth(stamp_list.data[0].id, stamp_list.data[0].auth_type, stamp_list.data[0].shop_id)">STAMP GET</v-btn>
            </v-row>
          </v-col>
          <v-col class="text-right col-auto" style="width: 21px; line-height: 180px;">
            <a>
              <v-icon class="maincolor">keyboard_arrow_right</v-icon>
            </a>
          </v-col>
        </v-row>
      </v-card>
      <ul class="itemList pr-5" v-else>
        <li v-for="(stamp, i) in stamp_list.data" :key="i">
          <v-card class="itemCard stamp ml-5 elevation-3" style="overflow: auto;">
            <v-row
              align-content="start"
              justify="center"
              no-gutters
              style="width: 305px; height: 180px; overflow: hidden;"
            >
              <v-col class="col-auto" style="width: 150px;">
                <v-img :src="stamp.image_path" width="150px" height="180px"></v-img>
              </v-col>
              <v-col class="col-auto" style="width: 140px;">
                <v-row
                  no-gutters
                  style="height: 65px;"
                  class="align-center"
                >
                  <p
                    class="pl-2 d-inline-block"
                    style="width: 120px; font-size: 14px; line-height: 14px; font-weight:bold;"
                  >{{stamp.title}}</p>
                </v-row>
                <v-row style="min-height:70px" no-gutters>
                  <p
                    v-if="stamp.expiration_date"
                    class="pl-2 expiration_date_text"
                    style="width: 120px;"
                  >有効期限：{{stamp.expiration_date}}<br>
                  対象店舗：{{stamp.shop_names}}
                  </p>
                  <p
                    v-else
                    class="pl-2 expiration_date_text"
                    style="width: 120px;"
                  >対象店舗：{{stamp.shop_names}}</p>
                </v-row>
                <v-row class="align-end" justify="center" no-gutters>
                  <v-btn
                    class="buttonA"
                    depressed style="width: 112px;"
                    @click.stop="toStampAuth(stamp.id, stamp.auth_type, stamp.shop_id)"
                  >STAMP GET</v-btn>
                </v-row>
              </v-col>
              <v-col
                class="text-right col-auto"
                style="width: 15px; line-height: 180px; position: relative; left: -11px;"
              >
                <a>
                  <v-icon class="maincolor">keyboard_arrow_right</v-icon>
                </a>
              </v-col>
            </v-row>
          </v-card>
        </li>
      </ul>
    </v-row>
  </div>
</template>
<script>
export default {
  props: ['menu', 'stamp_list'],
  watch: {
    stamp_list: {
      handler: function (v) {
        if (v && v.data && v.data.length > 0) {
          this.dataExists = true;
        }
      },
      deep: true
    }
  },
  data () {
    return {
      dataExists: false,
      noStampBack: require("@/assets/top-b-back4.png"),
    };
  },
  created() {
  },
  methods: {
    toStampAuth(id, auth_type, shop_id){
      this.loading = true
      var destination = null
      var screenID = null
      switch (auth_type) {
        case 1:
          destination = 'stampAuthCodeActivity'
          screenID = 'stamp_auth_code'
          break;
        case 2:
          destination = 'stampAuthQrActivity'
          screenID = 'stamp_auth_qr'
          break;
        case 3:
          destination = 'stampAuthBeaconActivity'
          screenID = 'stamp_auth_beacon'
          break;
        case 4:
          destination = 'stampAuthWifiActivity'
          screenID = 'stamp_auth_wifi'
          break;
        default:
          // 20210203 NEW_DEV-1407 cyber start
          destination = 'stampAuthCodeActivity'
          screenID = 'stamp_auth_code'
          break;
          // 20210203 NEW_DEV-1407 cyber start
      }
      var tonative_req = {
        'destination': destination,
        'feature_id': 'stamp',
        'screen_id': screenID,
        'transition_type': 3,
        'stamp_id': id,
        'shop_id': shop_id
      };

      if (!(this.screenTransition(tonative_req))) {
        this.loading = false
        this.callDialog('遷移エラー', '遷移情報が不正です。再度アクセスして下さい。', 2);
      }else{
        this.loading = false
      }
    },
  }
}
</script>

<style scoped>
.top-b {
  width: 375px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
  font-family: "游ゴシック体"; /* ページ内のデフォルトのフォント設定 */
}
.logo {
  width: 375px;
  height: 50px;
  position: relative;
  z-index: 999;
  background-color: #ffffff;
  box-shadow: 0 3px 10px #00000029;
}
.body {
  overflow-y: auto;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.body::-webkit-scrollbar {
  /* Chrome, Safari 対応 */
  display: none;
}
.buttonA {
  width: 143px;
  height: 32px;
  color: #ffffff;
  background-color: var(--main_color) !important;
  border-radius: 20px;
  font: bold 11px "Roboto"; /* 本来14pxだが、それに合わせると見た目がズレるので微修正した */
}
.itemButton {
  width: 120px;
  height: 30px !important;
  padding: 0 !important;
  background-color: #ffffff !important;
  -webkit-filter: drop-shadow(0 0 10px #00000033);
  filter: drop-shadow(0 0 10px #00000033);
}
/* 基本カードレイアウト */
.cards {
  position: relative;
  z-index: 15;
}
.itemCaption {
  line-height: 1;
}
.itemButton {
  width: 120px;
  height: 30px !important;
  padding: 0 !important;
  background-color: #ffffff !important;
  filter: drop-shadow(0 0 10px #00000033);
}
.itemNon {
  /* データが無い場合 */
  width: 335px;
  height: 180px;
  border-radius: 16px 16px 16px 16px !important;
  text-align: center;
  line-height: 180px;
  color: #9d9d9d;
  font-size: 14px;
}
.itemOne {
  /* データが1件だけの場合 */
  width: 335px;
  height: 314px;
  border-radius: 16px 16px 16px 16px !important;
}
.itemList {
  /* データが2件以上の場合 */
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.itemList::-webkit-scrollbar {
  display: none;
}
.itemList li {
  display: inline-block;
}
.itemCard {
  width: 305px;
  height: 314px;
  border-radius: 16px 16px 16px 16px !important;
  overflow: auto;
  /* filter: drop-shadow(0px 5px 15px #00000066); */
}

/* 基本カード内のレイアウト */
.itemOne .v-image,
.itemList .v-image {
  border-radius: 16px 0px 0px 16px !important;
}
.itemFirstTime {
  margin: 0 0 0 16px;
  width: 78px;
  height: 14px;
  font-size: 9px;
  color: #ffffff;
  background-color: #eb762f !important;
}
.itemTitle {
  margin: 22px 0 0 0;
  font-size: 14px;
  line-height: 20px;
}
.itemSubTitle {
  margin: 0 0 0 16px;
  font-size: 9px;
  line-height: 13px;
}
.itemUnread {
  margin: 0 16px 2px 0;
  display: inline-flex;
  vertical-align: bottom;
  height: 14px;
  font-size: 9px;
  color: #ffffff;
  background-color: #9d9d9d !important;
}

/* カードの種類(基本との差分のみ記述) */
.stamp {
  height: 180px;
}
.stamp .v-image,
.stamp .v-image {
  width: 150px;
  height: 180px;
  border-radius: 10px 0px 0px 10px !important;
}
.stamp .itemTitle {
  margin: 0 0 0 0;
  width: 112px;
  height: 54px;
}
.stamp .itemUnread {
  display: inline;
}
.align-center {
  -webkit-align-content: center !important;
  -ms-align-content: center !important;
  align-content: center !important;
}
.align-end {
  -webkit-align-content: flex-end !important;
  -ms-align-content: flex-end !important;
  align-content: flex-end !important;
}
.expiration_date_text {
  font-size: 9px;
  line-height: 1.5em;
  word-break: break-all !important;
}
</style>
